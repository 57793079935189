<template>
  <div id="PaySuccessView" style="height: 100%">
    <div style="height: 28px"></div>
    <van-icon name="success" size="100" color="#67C23A" />
    <p>成功</p>
    <img src="https://static.jisubx.com/jisubx_gzh_qr_12.jpg">
    <p   style="size: 30px; color: #eb6f6f">请扫码上方二维码关注极速宝公众号，可以进行保单查看，后续理赔申请和进度查看。</p>
  </div>
</template>

<script>

export default {
  name:"PaySuccessView",
  data() {
    return {
    };
  },
  methods: {
  },
}
</script>
